$(function () {
    initNav();
    initSlick();
    initUsps();
    initProjectCarousel();
    initContact();
    initLightbox();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        $nav.children('ul').attr('style', false);
    }, 200));
});

function initNav() {
    $btn_navigation = $('header .nav-toggle');
    $nav = $('header nav');

    $btn_navigation.click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 768) {
            if ($('#search').length > 0) {
                $('#search').slideUp(function () {
                    $nav.slideToggle();
                });
            } else {
                $nav.slideToggle();

            }
        }
    });

    if ($nav.find('ul').length > 0) {
        $li = $nav.find('li');

        $li.has('ul').children('a').click(function (e) {

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle')
                    $(ul).slideUp();
                }
            });

            if ($(this).next('ul').is(':hidden')) {
                e.preventDefault();
                $(this).next('ul').slideDown();
                $(this).children('span').addClass('toggle')
            }
        });
    }
}

function initSlick() {
    if ($('#carousel').length > 0) {
        $('#carousel').slick({
            dots: true,
            arrows: false,
            autoplay: true
        });
    }
}

function initUsps() {
    if ($('#usps').length > 0) {
        $('#usps').slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }
}

function initProjectCarousel() {
    $('#projects_wrapper #projects').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

function initContact() {
    if ($('#contact').length > 0) {
        var latlng = new google.maps.LatLng(51.943365, 4.469528);

        map = new google.maps.Map(document.getElementById("maps"), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            //icon: '/img/icon-map.png'
        });
    }
}


function initLightbox() {
    $('.lightbox').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });
}